@font-face {
  font-family: fresh-pinkle;
  src: url(assets/Fresh_Pickle.ttf);
}

@font-face {
  font-family: poppins;
  src: url(assets/Poppins-Regular.ttf);
}

/* html {
  max-width: 100%;
  overflow-x: hidden;
  width: 100vw;
} */

body {
  overflow-x: hidden;
  background-color: #182c34 !important;
  width: 100vw;
  max-width: 100%;
}
/* 
main {
  width: 100vw;
} */

.navbar {
  position: absolute;
  z-index: 10000;
  width: 100vw;
  max-width: 100%;
  /* background-color: rgba(0, 0, 0, 0.3); */
  top: 0;
  left: 0;
}

.drawer {
  background: black !important;
  color: white !important;
  padding-top: 150px;
  width: 400px;
  text-align: center;
}

.drawer-item {
  display: flex !important;
  justify-content: center !important;
}

.drawer-item h3 {
  margin: 5px !important;
  letter-spacing: 1.5px;
  transition: letter-spacing 0.2s ease-in-out !important;
}

.drawer-item h3 a {
  color: inherit;
  text-decoration: inherit;
}

.logo-text {
  font-family: "fresh-pinkle", sans-serif;
  font-size: 40px;
  white-space: nowrap;
}

.hero-img {
  position: absolute;
  width: 100%;
}

.hero {
  top: 0;
  left: 0;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* position: absolute; */
}

.hero h1 {
  font-family: "fresh-pinkle", sans-serif;
  font-size: 185px;
  white-space: nowrap;
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translateX(-50%);
  letter-spacing: 4px;
  color: white;
}

@media (max-width: 960px) {
  .hero h1 {
    top: 30% !important;
    font-size: 100px !important;
  }

  .jar {
    padding-top: 300px;
  }
}

@media (max-width: 450px) {
  .contract-btn {
    font-size: 10px !important;
  }
  .countdown-item {
    padding: 15px !important;
  }

  .countdown-value {
    font-size: 20px !important;
  }

  .countdown-text {
    font-size: 10px !important;
  }

  .hero h1 {
    top: 20% !important;
    font-size: 60px !important;
  }

  h3 {
    font-size: 20px !important;
  }
  #roadmap {
    margin-bottom: 100% !important;
  }

  .drawer {
    width: 100vw !important;
  }
}

@media (max-width: 750px) {
  .countdown-container {
    margin-top: 270px !important;
    max-width: 95%;
    width: 95%;
    margin: auto;
    min-width: auto !important;
  }

  #about {
    padding-top: 0px !important;
  }

  h2 {
    font-size: 40px !important;
  }

  .discord-btn {
    font-size: 15px !important;
  }

  .logo-text {
    display: none;
  }
}

.jar {
  padding-top: 200px;
  z-index: 15;
  /* position: relative; */
}

@keyframes float {
  0% {
    transform: translatex(0px);
  }
  50% {
    transform: translatex(-15px);
  }
  100% {
    transform: translatex(0px);
  }
}

@keyframes float2 {
  0% {
    transform: translatex(0px);
  }
  50% {
    transform: translatex(35px);
  }
  100% {
    transform: translatex(0px);
  }
}

@keyframes float3 {
  0% {
    transform: translatex(0px);
  }
  50% {
    transform: translatex(-25px);
  }
  100% {
    transform: translatex(0px);
  }
}

@keyframes float4 {
  0% {
    transform: translatex(0px);
  }
  50% {
    transform: translatex(15px);
  }
  100% {
    transform: translatex(0px);
  }
}

@keyframes float5 {
  0% {
    transform: translatex(0px);
  }
  50% {
    transform: translatex(10px);
  }
  100% {
    transform: translatex(0px);
  }
}

.cloud1 {
  z-index: 10;
}

.cloud2 {
  z-index: 9;
}

.cloud3 {
  z-index: 8;
}

.cloud4 {
  z-index: 7;
}

.cloud5 {
  z-index: 6;
}

.cloud6 {
  z-index: 5;
}

.cloud7 {
  z-index: 4;
}

.cloud8 {
  z-index: 3;
}

.island {
  z-index: 11;
}

.pickle {
  z-index: 12;
  width: 150px;
  left: 50%;
  transform: translateX(-50%);
  bottom: 16%;
}

.pickle-juice {
  z-index: 10;
}

.footer-box {
  text-align: center;
}

.footer-box p {
  text-align: center;
}

.footer-socials {
  display: flex;
  justify-content: center;
}

.footer-container {
  padding: 10% 0px;
  margin: 0 !important;
  max-width: none !important;
  /* position: relative; */
  z-index: 20;
}

.footer-socials img {
  margin: 10px;
  height: 45px;
  cursor: pointer;
}

.logo-text-footer {
  font-family: "fresh-pinkle", sans-serif;
  font-size: 40px;
  color: white;
}

#faq,
#about,
#team {
  position: relative;
  z-index: 20;
}

.accordion {
  /* background-color: rgba(255, 255, 255, 0.05) !important; */
  background-color: #5c862b !important;
  color: white !important;
  border-radius: 25px !important;
  margin-bottom: 25px;
}

.accordion-details {
  margin-top: 0px !important;
}

.about-title {
  text-align: center;
}

#about {
  padding-top: 200px;
}

h2 {
  font-size: 80px;
  font-family: "fresh-pinkle" !important;
  letter-spacing: 4px !important;
  margin: 0px !important;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  z-index: 27;
  color: white;
}

.welcome-content {
  font-size: 18px;
  font-family: "poppins";
  letter-spacing: 2px;
  /* margin: 0px; */
  text-align: center;
}

.welcome-content p {
  text-align: center !important;
}

.welcome-box {
  position: relative;
  z-index: 25;
}

.container {
  margin: 10% 0;
  position: relative;
  z-index: 20;
}

p {
  font-size: 18px;
  font-family: "poppins";
  letter-spacing: 2px;
  /* margin: 0px; */
  text-align: left;
  color: white;
}

h3 {
  font-size: 30px;
  font-family: "fresh-pinkle";
  letter-spacing: 4px;
  text-align: center;
  color: white;
  text-transform: uppercase;
}

.accordion-details {
  text-align: center !important;
}

.floating-pickle {
  position: absolute;
  z-index: 15;
}

.float1 {
  transform: rotate(-20deg);
  left: 10%;
  opacity: 0.75;
  width: 250px;
  bottom: -30%;
}

.float2 {
  transform: rotate(75deg);
  right: 10%;
  opacity: 0.5;
  width: 150px;
  bottom: -15%;
}

.float3 {
  opacity: 0.8;
  width: 1200px;
  z-index: 26;
  bottom: -250%;
}

.float4 {
  opacity: 0.8;
  width: 1200px;
  z-index: 26;
  bottom: -270%;
}

.MuiAccordionSummary-content {
  justify-content: center;
  margin-bottom: 0px !important;
}

.timeline {
  height: 200px;
  width: 5px;
  background-color: #e5e5e5;
  margin-left: 13px;
}

.message {
  position: absolute;
  top: 20%;
  /* left: 50%; */
  left: 50px;
  min-width: 150px;
  /* font-weight: bold; */
  max-width: 80%;
}

.message h3 {
  text-align: left !important;
}

.circleWrapper {
  position: relative;
}

.circle {
  width: 30px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 50%;
  background-color: #e5e5e5;
}
/* 
@media (max-width: 1200px) {
  #about {
    padding-top: 400px !important;
  }
} */

.bottom {
  max-width: 100%;
  position: absolute;
  opacity: 0.9;
  left: 0;
}

@media (max-width: 750px) {
  .float4,
  .float3 {
    left: -100%;
  }

  .table-row,
  .table-body,
  .table-cell {
    display: block !important;
    text-align: left !important;
  }

  .table-row {
    border-top: 1px solid white !important;
  }

  .table-cell {
    border: none !important;
  }

  .table-cell-desc {
    padding-top: 0 !important;
  }

  .table-cell-desc p {
    margin-top: 0px !important;
  }

  #about {
    padding-top: 0px !important;
  }

  .timeline {
    height: 300px;
  }
}

.logo {
  width: 80px;
}

.discord-btn,
.mint-button {
  font-size: 25px;
  text-transform: uppercase;
  letter-spacing: 4px !important;
  font-family: fresh-pinkle !important;
  font-weight: 400 !important;
  font-style: normal;
  transition: letter-spacing 0.2s ease-in-out !important;
  color: white !important;
  background: black !important;
  min-width: 125px !important;
  cursor: pointer;
  padding: 12px 16px;
  border-radius: 4px;
  z-index: 1000;
  white-space: nowrap;
}

.discord-btn span,
.mint-button span {
  justify-content: center;
  font-size: 25px;
}

@media (min-width: 450px) {
  .discord-btn:hover,
  .mint-button:hover {
    letter-spacing: 6px !important;
  }
}

@media (min-width: 1450px) {
  #about {
    padding-top: 400px !important;
  }
}

@media (min-width: 750px) {
  .cloud1 {
    animation: float 10s ease-in-out infinite;
  }

  .cloud2 {
    animation: float2 8s ease-in-out infinite;
  }

  .cloud3 {
    animation: float3 9s ease-in-out infinite;
  }

  .cloud4 {
    animation: float2 8s ease-in-out infinite;
  }

  .cloud5 {
    animation: float5 8s ease-in-out infinite;
  }

  .cloud6 {
    animation: float5 6s ease-in-out infinite;
  }

  .cloud7 {
    animation: float5 7s ease-in-out infinite;
  }

  .cloud8 {
    animation: float5 6s ease-in-out infinite;
  }
}

.loader {
  width: 100vw;
  height: 100vh;
  display: flex !important;
  justify-content: center !important;
  margin: 0px !important;
  text-align: center !important;
  max-width: none !important;
  align-items: center !important;
  position: fixed;
  background-color: black;
  z-index: 1000000001;
  top: 0;
}
.logo-text-loader span {
  display: inline-block;
  font-family: fresh-pinkle;
  font-size: 50px;
  color: white;
  position: relative;
}

.loader-text {
  /* overflow: hidden; */
  padding-top: 10px;
}

.team-img {
  border: 5px solid white;
  border-radius: 65px;
  width: 90%;
  height: auto;
}

.team-desc {
  text-align: center;
}

.team-desc p {
  font-size: 25px;
  margin: 0px !important;
  text-align: center;
}

.team-desc h3 {
  font-size: 30px;
  margin-bottom: 0px !important;
}

.team-item-container {
  text-align: center;
  margin: 20px 0px !important;
}

.verify-button span,
.verify-button {
  font-size: 25px;
  text-transform: uppercase;
  letter-spacing: 4px !important;
  font-family: fresh-pinkle !important;
  font-weight: 400 !important;
  font-style: normal;
  transition: letter-spacing 0.2s ease-in-out !important;
  color: white !important;
  min-width: 125px !important;
  cursor: pointer;
  padding: 12px 16px;
  border-radius: 4px;
  z-index: 1000;
  white-space: nowrap;
}

.verify-button span {
  padding: 0px !important;
}

.verify-button {
  background: black !important;
  justify-content: center !important;
}

.verify-button span:hover,
.verify-button:hover {
  letter-spacing: 6px !important;
}

.verify-container {
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.alert .MuiAlert-icon {
  align-items: center;
}

.countdown-container {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -35%);
  text-align: center;
  /* margin-top: 40px; */
  z-index: 1000;
  min-width: 450px;
}

.countdown-container h3 {
  color: #3b3b3b;
}

.coutdown-grid {
  text-align: center;
}

.countdown-item {
  padding: 25px;
}

.countdown-border-right {
  border-right: solid 1px rgba(255, 255, 255, 0.5);
}

.countdown-value {
  font-size: 35px;
  margin: 0px !important;
  font-weight: 700;
  text-align: center;
}

.countdown-text {
  font-size: 16px;
  margin: 0px !important;
  font-weight: 700;
  color: #cdcdcd;
  text-align: center;
}

.mintButton-container {
  position: absolute;
  z-index: 100000;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 30px;
}

.incr-decr {
  background: rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  display: flex;
  margin-bottom: 30px;
  justify-content: center;
  max-width: 200px;
  margin: 10px auto;
}

.incr-decr h3 {
  margin: 10px;
}

.contract-btn {
  cursor: pointer;
}
